.footer {
  background-color: #02913f;
  color: white;
  padding: 20px;
  text-align: center;

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column; // Stack items vertically on small screens
    align-items: center;

    p {
      margin: 10px 0;
    }

    .footer-columns {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;

      .contact-info,
      .address-info {
        width: 45%; // Each column takes up 45% of the width
        text-align: left;

        h4 {
          margin-bottom: 10px;
          font-weight: 700;
        }

        p {
          margin: 5px 0;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }

          a {
            color: white;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

// Media Queries for Responsive Design
@media (max-width: 768px) {
  .footer {
    .footer-content {
      .footer-columns {
        flex-direction: column; // Stack columns on small screens
        align-items: center; // Center align items

        .contact-info,
        .address-info {
          width: 100%; // Full width for each column
          text-align: center; // Center text for better readability

          p {
            justify-content: center; // Center align icons and text
          }
        }
      }
    }
  }
}