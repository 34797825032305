.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #02913f, #ff0000);

  .login-box {
    background: white;
    padding: 5rem; /* Perbesar padding */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 600px; /* Perbesar lebar */
    text-align: center;
  }

  .MuiButton-root {
    background-color: #02913f !important; /* Warna tombol hijau */
    color: white;
  }
}
