.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #02913f, #F44336);
}

.reset-password-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

button {
  margin-top: 20px;
}
