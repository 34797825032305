* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

:root {
  --primary-bg-color: rgb(255, 255, 255);
  --text-color: #02913f;
  --toggler-border-color: #02913f;
  --shadow-color: rgba(168, 168, 168, 0.479);
  overflow-x: hidden;
}

.navbar {
  background-color: var(--primary-bg-color);
  padding: 1rem 2rem;
  box-shadow: 0 8px 10px var(--shadow-color);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-width: 130px;
  }
  .judul-landing-page {
  font-size: 1.5rem; // Base font size
  margin-left: 10px; // Space between logo and text
  flex: 1; // Allow the title to grow and take available space
  text-align: center; // Center text for better appearance
  font-weight: 700; // Same font weight as nav links
  color: #000; // Set text color to black
  font-family: inherit; // Inherit font family from parent or body
  }
  .nav-links {
    margin-left: auto;
    display: flex;

    .nav-link-item {
      margin-left: 20px;
      color: var(--text-color);
      font-weight: 700;
      font-size: 20px;
      padding: 0.5rem 1rem;
      text-decoration: none;
    }
  }

  // Media Query for Small Devices (max-width: 576px)
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 0.5rem;

    .nav-links {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 1rem;

      .nav-link-item {
        font-size: 18px;
        margin: 0.5rem 0;
        padding: 0.5rem 0;
      }
    }

    .logo {
      width: 8rem;
      margin-left: 0;
    }
  }

  // Media Query for Medium Devices (max-width: 768px)
  @media (max-width: 768px) {
    .nav-links .nav-link-item {
      font-size: 19px; // Slightly larger than small screens
    }
  }

  // Media Query for Large Devices (max-width: 992px)
  @media (max-width: 992px) {
    padding: 1rem 1.5rem; // Adjust padding for larger screens

    .nav-links .nav-link-item {
      font-size: 20px; // Default font size for larger screens
    }
  }

  // Media Query for Extra Large Devices (min-width: 993px)
  @media (min-width: 993px) {
    padding: 1rem 2rem; // Default padding for extra large screens

    .nav-links .nav-link-item {
      margin-left: 20px; // Default margin for links
    }
  }
}