.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);

  &.open {
    transform: translateX(0);
  }
}

.menu-btn {
  position: fixed;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.close-btn-sidebar {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.center-sidebar {
  padding: 10px;
  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 10px 0;
      a {
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
  }
}

.logout-btn {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logout-icon {
  margin-right: 8px;
}
