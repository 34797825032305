.user-data {
  padding: 10px;
}

.modal-content-userdata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.user-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.table-container {
  margin-top: 20px;
}
.user-data-container {
  width: 100%;
  overflow-x: auto;
}
.scrollable-table {
  overflow-x: auto;
  max-height: 80vh; 
  display: block;
}
.scrollable-table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
}


.table {
  width: 100%;
  min-width: 1000px; 
}

thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

tfoot {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}

tbody {
  display: block;
  height: 60vh; /* Adjust according to your needs */
  overflow-y: auto;
}
@media (max-width: 768px) {
  .scrollable-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table {
    min-width: 600px; /* Adjust based on your content */
  }
}

