.form-container {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

form {
  display: flex;
  flex-direction: column;
}

.MuiTextField-root {
  margin-bottom: 1rem;
}

button {
  margin: 0.5rem 0; /* Jarak antara tombol */
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.MuiAlert-root {
  margin-top: 1rem;
}

.submit-buttons {
  display: flex;
  justify-content: flex-end; /* Menempatkan tombol-tombol di sisi kanan */
  gap: 1rem; /* Jarak antara tombol */
  
}
/* UserInput.scss */
.menu-item {
  position: relative;
}

.menu-item .submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1;
}

.menu-item:hover .submenu {
  display: block;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1;
}

.submenu-item {
  padding: 8px 16px;
}

.submenu-item:hover {
  background-color: #f0f0f0;
}
@media (max-width: 600px) {
  .form-container {
    padding: 1rem;
  }

  .MuiTextField-root {
    margin-bottom: 0.5rem;
  }

  button {
    width: 100%; // Make buttons full-width on small screens
  }
}