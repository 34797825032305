.inspo-program-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.image-container { 
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-container {
  flex: 1;
  background-color: #28a745; /* Warna hijau */
  color: white;
  border-radius: 0 10px 10px 0;
  padding: 20px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    margin: 0;
    font-size: 1rem;
    text-align: center;
  }
}
