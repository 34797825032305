.icon-text-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
}

.icon-text-item {
  background: #02913f;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  padding: 16px;
  transition: transform 0.3s, background-color 0.3s;

  &:hover {
    transform: translateY(-5px);
    color: #fff;
    background-color: #8b0015; /* Warna merah saat di-hover */
  }
}

.icon-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-box {
  background-color: #f9f9f9 !important; /* Warna latar belakang ikon */
  color: #02913f !important; /* Warna ikon */
  border-radius: 50%; /* Mengatur ikon menjadi lingkaran */
  width: 120px; /* Lebar tetap untuk memastikan lingkaran */
  height: 120px; /* Tinggi tetap untuk memastikan lingkaran */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s, transform 0.3s; /* Menambahkan transisi transformasi */

  &:hover {
    background-color: #ffffff !important; /* Warna putih saat di-hover */
    color: #8b0015 !important; /* Warna teks berubah saat di-hover */
    transform: scale(1.1); /* Menambahkan efek zoom saat di-hover */
  }

  .icon {
    width: 80px; /* Ukuran ikon */
    height: 80px; /* Ukuran ikon */
    
    &:hover {
      
      fill: #8b0015 !important; /* Warna ikon saat di-hover */
    }
  }
}

.icon-text-title {
  color: #ffffff;
  font-size: 1.5rem !important; /* Ukuran teks */
  font-weight: 750 !important; /* Mempertebal teks */
 


}

.icon-text-description {
  color: #fff;
  margin-top: 8px;
}

.icon-text-description-text {
  font-weight: 200 !important; /* Mempertebal teks deskripsi */
  font-size: 1rem !important; /* Ukuran teks deskripsi */
}
