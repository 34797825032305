:root{
  blue: #3490dc;
    --indigo: #6574cd;
    --purple: #9561e2;
    --pink: #f66d9b;
    --red: #e3342f;
    --orange: #f6993f;
    --yellow: #ffed4a;
    green: #02913f;
    --teal: #4dc0b5;
    --cyan: #6cb2eb;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #3490dc;
    --secondary: #6c757d;
    --success: #38c172;
    --info: #6cb2eb;
    --warning: #ffed4a;
    --danger: #e3342f;
    --light: #f8f9fa;
    --dark: #343a40;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
