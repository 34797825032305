.carousel-container {

    width: 60%;  /* Mengatur lebar container carousel */
    margin: 0 auto;
    position: relative;  /* Menambahkan posisi relatif untuk tombol geser */
  }
  
  .carousel-image {
    margin-top: 80px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(206, 12, 12, 0.1);
  }
  
  .custom-arrow {
    width: 30px;
    height: 30px;
    background-color: rgba(234, 246, 240, 0.7);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    position: absolute;  /* Mengatur posisi absolut untuk tombol geser */
    top: 50%;  /* Mengatur posisi vertikal tombol geser */
    transform: translateY(-50%);  /* Menyesuaikan tombol geser agar sejajar secara vertikal */
    z-index: 1;  /* Menambah z-index agar tombol geser di atas gambar */
  }
  
  .custom-arrow:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .custom-next-arrow:before, .custom-prev-arrow:before {
    font-size: 16px;
    color: black;
  }
  
  .custom-next-arrow {
    right: 10px;  /* Mengatur posisi kanan untuk tombol geser berikutnya */
  }
  
  .custom-prev-arrow {
    left: 10px;  /* Mengatur posisi kiri untuk tombol geser sebelumnya */
  }
  