.userSingle {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
  }
  
  .page-header {
    text-align: center;
    margin: 20px 0;
  }
  
  .page-header h1 {
    font-size: 2.5rem;
    color: #02913f;
    margin-bottom: 10px;
  }
  
  .page-header h2 {
    font-size: 1.8rem;
    color: #d71525;
    margin: 0;
  }
  
  /* Ensure content sections are clear of navbar and footer */
  .content {
    flex: 1; /* Allow content to grow and take available space */
  }
  
  
  .userSingle .userSingle img {
    width: 100%; /* Ensure images take full width */
    height: auto; /* Maintain aspect ratio */
  }

  .page-header h1 {
    margin-top: 20px;
    font-size: 2.5rem; /* Larger font size for the main title */
    color: #c60808; /* Green color for the main title */
    font-weight: 800;
    margin-bottom: 10px; /* Space below the main title */
  }
  
  .page-header h2 {
    font-weight: 600;
    font-size: 1.5rem; /* Slightly smaller font size for the subtitle */
    color: #010101; /* Red color for the subtitle */
    margin: 0; /* Remove default margin */
  }