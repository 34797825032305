.first-hero {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .first-hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .first-hero .container-first-hero {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 80%; /* Adjust this value to place the box between the middle and bottom */
    z-index: 1;
  }
  
  .first-hero .text-box {
    background-color: #02913f;
    padding: 20px 30px;
    border-radius: 20px;
    width: 50%;
    text-align: center;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .first-hero .text-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
    z-index: 0;
  }
  
  .first-hero .text-box > * {
    position: relative;
    z-index: 1;
  }
  
  
  .first-hero span {
    // margin-top: 25px;
    font-size: 25px;
    margin-bottom: 10px;
  }
  
  .first-hero h1 {
    font-size: 20px;
    font-weight: 500;
  }
  