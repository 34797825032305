.program-page {
    // background-image: linear-gradient(rgba(0,0,0,0.50),
    // rgba(0,0,0,0.50)), url(../../Assets/foto/images-2.jpeg);
    overflow: hidden;
}
// * {
//     box-sizing: border-box;
//   }
.clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  .first-hero-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .program-header {
    text-align: center;
    margin: 20px 0;
  }
  
  .program-header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #02913f;
    margin-bottom: 10px;
  }
  
  .program-header h2 {

    font-weight: 500;
    font-size: 1rem;
    color: #000000;
    margin: 0;
    margin-bottom: 30PX;
  }