.adminHome {
  display: flex;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #02913f;
  border-right: 0.5px solid rgb(230, 227, 227);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 1000;
}

.sidebar.open {
  transform: translateX(0);
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #02913f;
  color: white;
}

.close-btn, .menu-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
}

.center {
  padding: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10px 0;
}

li a {
  text-decoration: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

li a:hover {
  background-color: #1a5f7a;
}

.menu-btn {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #005b2f;
}

.mainContent {
  margin-left: 250px;
  padding: 20px;
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    transform: translateY(-100%);
  }

  .sidebar.open {
    transform: translateY(0);
  }

  .mainContent {
    margin-left: 0;
    margin-top: 60px;
  }
}
