.first-hero-landingpage {
  position: relative;
  width: 100%;
  height: 90vh;
}

.first-hero-landingpage img {
  width: 100%; /* Ensures the image spans the full width */
  height: 100%; /* Ensures the image covers the entire height */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.first-hero-landingpage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(54, 79, 65, 0.629);
  z-index: 0;
}

.container-landingpage {
  position: relative;
  z-index: 1;
  color: #fff;
  display: flex;
  flex-direction: column; /* Arrange elements in a column */
  align-items: center; /* Center elements horizontally */
  justify-content: center; /* Center elements vertically */
  height: 100%; /* Ensure container takes the full height */
  text-align: center; /* Center text inside the container */
  padding: 0 1rem; /* Horizontal padding for space */
}

.first-hero-landingpage h1 {
  margin-top: 15px;
  font-size: 22px; /* Adjusted font size */
  font-weight: 600;
}

.first-hero-landingpage span {
  margin-top: 300px; /* Removed extra margin */
  font-size: 40px; /* Adjusted font size */
  font-weight: 800;
}
