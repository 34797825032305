.container-userdetail {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #ffffff;

  .user-info-translation {
    font-size: 0.8em; 
    color: #666; 
    margin-left: 0px; 
    display: inline;
  }
  .paper-detail {
    padding: 20px; /* Kurangi padding untuk mengurangi ukuran kontainer */
    width: 100%;
    // max-height: 600px;
    max-width: 900px; /* Kurangi lebar maksimum untuk ukuran yang lebih kecil */
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dua kolom untuk layar besar */
    gap: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px; /* Lebih kecil dari sebelumnya */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sesuaikan bayangan */
    position: relative; /* Untuk menambahkan elemen desain */
    overflow: hidden; /* Pastikan dekorasi tidak meluber */
    
    /* Border dekoratif */
    border: 4px solid transparent; /* Kurangi ketebalan border */
    border-image: linear-gradient(45deg, #02913f, #d71525); /* Border gradient */
    border-image-slice: 1; /* Gunakan seluruh gradient untuk border */
  }

  .header {
    grid-column: span 2; /* Header mencakup dua kolom */
    color: #333;
    margin-bottom: 20px;
    font-size: 1.8rem; /* Sesuaikan ukuran font header */
    font-weight: bold;
    text-align: center;
    z-index: 1; /* Di atas elemen desain */
  }

  .user-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    grid-column: 1 / 2; /* Gambar di kolom pertama */
    z-index: 1; /* Di atas elemen desain */
  }

  .user-info {
    display: grid; /* Gunakan layout grid */
    grid-template-columns: 1fr 1fr; /* Dua kolom untuk informasi pengguna */
    gap: 8px; /* Jarak antar item */
    padding: 10px;
    text-align: left;
    grid-column: 2 / 3; /* Informasi pengguna di kolom kedua */
    z-index: 1; /* Di atas elemen desain */
  }

  .user-info-item {
    display: flex;
    flex-direction: column;
    gap: 0px; /* Jarak antar item */
    
    .user-info-label {
      text-decoration: underline;
      font-weight: bold;
      color: #02913f;
      font-size: 1rem; /* Ukuran font label */
    }

    .user-info-value {
      color: #333;
      font-size: 1rem; /* Ukuran font nilai */
    }
  }
}

@media (max-width: 768px) {
  .paper-detail {
    grid-template-columns: 1fr !important; /* Paksa satu kolom */
  }

  .user-image {
    grid-column: 1 / 2 !important;
    margin-bottom: 20px !important; /* Tambah margin di bawah gambar */
  }

  .user-info {
    grid-column: 1 / 2 !important;
    grid-template-columns: 1fr !important; /* Satu kolom untuk informasi pengguna */
  }
}
